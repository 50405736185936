import React from "react"
import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import EventsSectionNew from "../components/sections/EventsSectionNew"

const EventsPage = () => (
  <MainContainer>
    <HeadData
      title='Events'
      description='List of events from Command Access.'
    />

    <div>
      <EventsSectionNew />
    </div>
  </MainContainer>
)

export default EventsPage